import { computed, ref } from 'vue';
import VueCookies from 'vue-cookies';

import { setAuth } from '~/services/auth.api';
import { useDeviceProfiler } from '~/hooks/useDeviceProfiler';
import router from '~/router';
import {
  getApplicantInfo,
  loginByClientNotFound,
  loginByDeviceNotFound,
  loginByExpiredPassport,
} from '~/services/registration.api';
import { setApiClientHeader } from '~/services/apiClient';
import { useService } from '~/hooks/useService';

const COOKIE_REGISTRATION_KEY = 'registration_uuid';
const API_HEADER_NAME = 'Applicant-Key';
const PROCESS_TYPES_KEYS = {
  clientNotFound: 'client_not_found',
  deviceNotFound: 'device_not_found',
  passportIsExpired: 'passport_is_expired',
};

const applicant = ref(null);

export const FE_ERROR_CODES = {
  phoneChangeFailed: 'phoneChangeFailed',
};
export const APPLICANT_EXPIRE_SECONDS = 600; // 10 minutes
export const LOGIN_ERROR_CODES = {
  redirectToMyId: 'redirect_to_myid',
  passportIdExists: 'passport_id_exists',
  phoneExists: 'client_phone_exists',
};
export const IDENTIFICATION_STATUSES = {
  pending: 'processing',
  error: 'failed',
  success: 'completed',
};
export const COOKIE_REMEMBER_ME_KEY = 'remember_me';
export const COOKIE_OCR_BAN_KEY = 'registration_ocr-ban';
export const SESSION_STORAGE_PASSPORT_DATA_KEY = 'registration_applicant-passport-data';

const deviceProfiler = useDeviceProfiler();

export const useAuthModel = () => {
  const services = ref({
    getApplicantInfo: useService(getApplicantInfo),
    loginByClientNotFound: useService(loginByClientNotFound),
    loginByDeviceNotFound: useService(loginByDeviceNotFound),
    loginByExpiredPassport: useService(loginByExpiredPassport),
  });
  const loginServiceErrors = computed(
    () =>
      services.value.loginByClientNotFound.errros ||
      services.value.loginByDeviceNotFound.errros ||
      services.value.loginByExpiredPassport.errros ||
      {}
  );
  const loginServiceAlert = computed(
    () =>
      services.value.loginByClientNotFound.alert ||
      services.value.loginByDeviceNotFound.alert ||
      services.value.loginByExpiredPassport.alert ||
      {}
  );

  const authorize = ({ access_token, token_type, expires_in }, route, rememberMe = undefined) => {
    const withRememberMe = rememberMe ?? VueCookies.get(COOKIE_REMEMBER_ME_KEY) ?? false;
    setAuth({ access_token, token_type, expires_in }, withRememberMe);
    const redirect = route.query.from ? { path: route.query.from } : { name: 'App.Index' };
    deviceProfiler.allowCollect();
    router.replace(redirect);
  };

  const setApplicantUuidApiHeader = uuid => {
    return setApiClientHeader(API_HEADER_NAME, uuid);
  };

  const setApplicant = applicantInfo => {
    applicant.value = applicantInfo;
  };

  const initApplicant = applicantInfo => {
    setApplicant(applicantInfo);
    VueCookies.set(COOKIE_REGISTRATION_KEY, applicantInfo.uuid, APPLICANT_EXPIRE_SECONDS);
    setApplicantUuidApiHeader(applicantInfo.uuid);
    router.replace({ name: 'Auth.Identification' });
  };

  const getApplicantUuidFromCookie = () => {
    return VueCookies.get(COOKIE_REGISTRATION_KEY);
  };
  const checkHasApplicantUuid = () => {
    return Boolean(getApplicantUuidFromCookie());
  };

  const applicantLoading = computed(() => services.value.getApplicantInfo.loading);
  const loadApplicant = () => {
    const uuid = getApplicantUuidFromCookie();

    if (applicantInfo.value) {
      return applicantInfo.value;
    } else {
      setApplicantUuidApiHeader(uuid);
      services.value.getApplicantInfo.execute().then(response => {
        setApplicant(response.data);
        return response;
      });
    }
  };

  const applicantInfo = computed(() => applicant.value);
  const applicantProcessType = computed(() => applicantInfo.value.process_type);

  const loginLoading = computed(
    () =>
      services.value.loginByExpiredPassport.loading ||
      services.value.loginByDeviceNotFound.loading ||
      services.value.loginByClientNotFound.loading
  );
  const login = async () => {
    if (applicantProcessType.value === PROCESS_TYPES_KEYS.clientNotFound) {
      return services.value.loginByClientNotFound.execute();
    } else if (applicantProcessType.value === PROCESS_TYPES_KEYS.passportIsExpired) {
      return services.value.loginByExpiredPassport.execute();
    } else if (applicantProcessType.value === PROCESS_TYPES_KEYS.deviceNotFound) {
      try {
        return await services.value.loginByDeviceNotFound.execute();
      } catch (e) {
        if (e.code === LOGIN_ERROR_CODES.redirectToMyId) {
          VueCookies.set(COOKIE_OCR_BAN_KEY, JSON.stringify(true), APPLICANT_EXPIRE_SECONDS);
          router.replace({ name: 'Auth.Identification.MyId' });
        }
        throw e;
      }
    }
  };

  const clearApplicant = () => {
    setApplicant(null);
    setApplicantUuidApiHeader(null);
    VueCookies.remove(COOKIE_REGISTRATION_KEY);
    VueCookies.remove(COOKIE_OCR_BAN_KEY);
    sessionStorage.removeItem(SESSION_STORAGE_PASSPORT_DATA_KEY);
  };

  return {
    authorize,
    setApplicant,
    applicantInfo,
    initApplicant,
    loadApplicant,
    checkHasApplicantUuid,
    getApplicantUuidFromCookie,
    setApplicantUuidApiHeader,
    applicantLoading,
    login,
    loginLoading,
    clearApplicant,
    loginServiceErrors,
    loginServiceAlert,
  };
};
